import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from 'src/store'
import { getRuntimeEnv } from 'src/utils/getRuntimeEnv'

const getDefaultApiConfig = ({
    baseEndpoint = '',
    optHeader,
}: {
    baseEndpoint?: string
    optHeader?: Array<{ key: string; value: string }>
}) => {
    return {
        baseQuery: fetchBaseQuery({
            baseUrl: `${getRuntimeEnv('VITE_API_URL')}/${baseEndpoint}`,
            prepareHeaders(headers, { getState }) {
                // documentation say to type cast (https://redux-toolkit.js.org/rtk-query/api/fetchBaseQuery#setting-default-headers-on-requests)
                const token = (getState() as RootState).app.apiToken
                if (token) {
                    headers.set('X-Token', token)
                }
                //
                if (optHeader) {
                    optHeader.forEach((header) => {
                        headers.set(header.key, header.value)
                    })
                }

                return headers
            },
        }),
        refetchOnMountOrArgChange: 60,
    }
}

const createGlobalAPI = () => {
    return createApi({
        ...getDefaultApiConfig({}),
        reducerPath: 'globalAPI',
        endpoints: () => ({}),
    })
}

export const globalAPI = createGlobalAPI()
