import { RuntimeEnv, runtimeEnvSchema } from './runtimeEnvSchema'

// if fallback is defined, the return type is defined by the schema
export function getRuntimeEnv(
    name: keyof RuntimeEnv,
    fallback: RuntimeEnv[typeof name]
): RuntimeEnv[typeof name]

// if fallback is not defined, the return type can be undefined
export function getRuntimeEnv(
    name: keyof RuntimeEnv
): RuntimeEnv[typeof name] | undefined

// the function implementation:
export function getRuntimeEnv(
    name: keyof RuntimeEnv,
    fallback?: RuntimeEnv[typeof name]
) {
    if (window.hasRuntimeEnv) {
        const runtimeEnv = runtimeEnvSchema
            .strict()
            .safeParse(window.appRuntimeEnv)
        if (runtimeEnv.success) return runtimeEnv.data[name]

        return null
    }

    return import.meta.env[name] || fallback
}
